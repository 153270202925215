import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
//@ts-ignore
import validator from "validator";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp: number | string;
  isOTPsend: boolean;
  isVerified: boolean;
  otpError: string;
  token: string;
  formData: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
  };
  second: number,
  showPassword: boolean,
  showConfirmPassword: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyEmail: string = "";
  verifyOtp: string = "";
  newPassword: string = "";
  interval: number | ReturnType<typeof setInterval> = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area Start

    this.state = {
      otp: "",
      errors: {},
      token: "",
      otpError: "",
      isOTPsend: false,
      formData: {},
      isVerified: false,
      toast: {
        type: "success",
        open: false,
        message: "Password changed successfully"
      },
      second: 60,
      showPassword: false,
      showConfirmPassword: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.handleApiResponse(apiRequestCallId, responseJson, errorReponse);
    }
  }

  startCountdown = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.setState({ second: 60 }, () => {
      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.second > 0) {
            return { second: prevState.second - 1 };
          } 
          return { second: 0 };
        });
      }, 1000);
    });
  };

  handleApiResponse = (
    apiId: string,
    responseJson: {
      errors: { message: string };
      meta: { token: string };
      messages: { otp: string };
    },
    _errorResponse: object
  ) => {
    const error = responseJson.errors ? responseJson.errors.message : "";
    const token = responseJson.meta ? responseJson.meta.token : "";
    if (apiId === this.verifyEmail) {
      this.handleVerifyEmail(error, token);
    }
    if (apiId === this.verifyOtp) {
      if (!error && responseJson.messages.otp) {
        this.setState({
          isVerified: true
        });
      } else {
        this.setState({ otpError: error || "somthing went wrong" });
      }
    }
    if (apiId === this.newPassword) {
      if (!error) {
        this.setState({
          toast: {
            type: "success",
            open: true,
            message: "Password changed successfully"
          }
        });
        this.handleClose({}, "escapeKeyDown");
      }
    }
  };

  handleVerifyEmail = (error: string, token: string) => {
    if (!error && token) {
      this.setState({
        isOTPsend: true,
        token: token
      });
    } else {
      this.setState({
        errors: { email: error }
      });
    }
  };
  handleTostClose = () => {
    this.setState({
      toast: {
        ...this.state.toast,
        open: false
      }
    });
  };
  handleChange = (e: { target: { value: string; name: string } }) => {
    this.setState({ errors: { ...this.state.errors, [e.target.name]: "" } });
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    });
  };
  handleOtpChange = (e: number) => {
    this.setState({ otp: e, otpError: "" });
  };
  sendOtp = () => {
    this.setState({otp: "", otpError: ""})
    this.startCountdown();
    if (this.state.formData.email) {
      if (!validator.isEmail(this.state.formData.email)) {
        return this.setState({
          errors: { ...this.state.errors, email: "Enter valid email" }
        });
      }
      const body = {
        data: {
          attributes: {
            email: this.state.formData.email
          }
        }
      };
      this.verifyEmail = sendAPIRequest(configJSON.verifyEmail, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json"
        }
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, email: "Enter email address" }
      });
    }
  };
  handleClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      return;
    }
    this.props.handleClose();
    this.setState({
      otp: "",
      formData: {},
      errors: {},
      otpError: "",
      isOTPsend: false,
      isVerified: false,
      second: 60,
      token: ""
    });

  };

  handleCloseCrossIcon = () => {
    this.props.handleClose();
    this.setState({
      errors: {},
      formData: {},
      otp: "",
      otpError: "",
      second: 60,
      token: "",
      isVerified: false,
      isOTPsend: false,
    });
  }

  handleSubmit = () => {
    let error = {
      confirmPassword: !validator.equals(
        this.state.formData.password,
        this.state.formData.confirmPassword
      )
        ? "Password and confirm Password not matched"
        : "",
      password: !validator.isStrongPassword(this.state.formData.password, [
        {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        }
      ])
        ? 
        "Password should be atleast 8 characters long and must include number, symbol, uppercase and lowercase letter"
        : ""
    };
    this.setState({ errors: { ...error } });
    let temp = [];
    for (const [key, value] of Object.entries(error)) {
      key && temp.push(value === "");
    }
    if (!temp.includes(false)) {
      const body = {
        data: {
          new_password: this.state.formData.password
        }
      };
      this.newPassword = sendAPIRequest(configJSON.newPass, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          token: this.state.token
        }
      });
    }
  };

  handleVerify = () => {
    const body = {
      data: {
        otp_code: this.state.otp
      }
    };
    this.verifyOtp = sendAPIRequest(configJSON.verifyOtp, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
        token: this.state.token
      }
    });
  };

  handleShowHidePassword = (name: keyof S) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }
  // Customizable Area End
}
