import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    handleopenfuturesOrIntraday: () => void
    handleSendData: (type: string) => void;
    changeTab?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    futureIntraday: boolean;
    partnershipFirmCat: boolean;
    type: string;
    futuresAndOptions: boolean;
    partnershipFirmForm: boolean;
    regularBusinessIncome: boolean;
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    regular_business: number;
    income_from_partnership_firms_or_playing_of_truks: number;
    income_from_futures_or_intraday: number;
    professional_or_business_income: number;
    small_business: number;
    business_income: number;
    activeSmallBusinessForm: number;
    activeSmallBusinessFormError: any;
    smallBusinessFields: any;
    businessCodeList: any;
    financialParticularsError: any;
    alertMessageShow: any;
    alertType: any;
    alertMessage: any;
    smallBId:number;
    fyEndDate:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BusinessIncomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetTotalAmountListcallID: string = ""
    BusinessCodeID: string = ""
    SmallBusinessID: string = ""
    getSmallBusinessApiID: string = ""
    userDetailId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.state = {
            futureIntraday: false,
            type: "",
            futuresAndOptions: false,
            toast: {
                type: "success",
                open: false,
                message: "",
            },
            regular_business: 0,
            income_from_partnership_firms_or_playing_of_truks: 0,
            income_from_futures_or_intraday: 0,
            professional_or_business_income: 0,
            small_business: 0,
            business_income: 0,
            regularBusinessIncome: false,
            partnershipFirmCat: false,
            partnershipFirmForm: false,
            activeSmallBusinessForm: 1,
            smallBusinessFields: {
                business_or_profession: null,
                name_of_business: null,
                business_code: null,
                gross_receipt_or_turnover: "",
                gross_receipt_through_cheques: "",
                gross_receipt_through_cash: "",
                gross_profit: "",
                expenditure_related_to_activity: "",
                net_profit: "",
                cash_balance: "",
                inventory_stock: "",
                amounts_payable: "",
                amounts_receivable: ""
            },
            activeSmallBusinessFormError: {},
            financialParticularsError: {},
            businessCodeList: [],
            alertMessageShow: false,
            alertType: "",
            alertMessage: "",
            smallBId:0,
            fyEndDate:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getBusinessCodeList()
        this.getTotalAmount()
        this.getSmallBusiness()
        this.getUserDetails()
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiCallFunctions(apiRequestCallId, responseJson)
            }
            if (
                this.GetTotalAmountListcallID === apiRequestCallId
            ) {
                this.receiveAmountResponse(message);
            }
            if (
                this.getSmallBusinessApiID === apiRequestCallId
            ) {
                this.setSmallBDetails(responseJson)
            }
            if(this.userDetailId === apiRequestCallId){
                this.setUserData(responseJson)
            }
        }
    }
    setUserData = (apiResponse: {
        data: [
          {
            attributes: {
              assessment_year: {
                end_year: string
              }
            }
          }];
      }) => {
        if (apiResponse && apiResponse.data) {
          this.setState({
            fyEndDate: "31.03."+apiResponse.data[0].attributes.assessment_year.end_year
          });
        }
    }
    setSmallBDetails = (responseJson:any) => {
        const { data } = responseJson;

        if (data && data.length > 0) {
            const businessData = data[data.length-1];
            const {
                attributes: {
                    id,
                    business_or_profession,
                    name_of_business,
                    business_code,
                    gross_receipt_or_turnover,
                    gross_receipt_through_cheques,
                    gross_receipt_through_cash,
                    gross_profit,
                    expenditure_related_to_activity,
                    net_profit,
                    financial_particular: {
                        cash_balance,
                        inventory_stock,
                        amounts_payable,
                        amounts_receivable,
                    }
                }
            } = businessData;
    
            const smallBusinessDetails = {
                business_or_profession,
                name_of_business,
                business_code,
                gross_receipt_or_turnover,
                gross_receipt_through_cheques,
                gross_receipt_through_cash,
                gross_profit,
                expenditure_related_to_activity,
                net_profit,
                cash_balance,
                inventory_stock,
                amounts_payable,
                amounts_receivable
            };
            this.setState({ smallBusinessFields :smallBusinessDetails,smallBId:id});
        } 
    }
    isFormFilled = (newErrors: any, state: any) => {
        newErrors.error = false
        for (const key in state) {
            if (state[key]) {
                if (state[key].toString().length > 0) {
                    delete newErrors.error
                    break
                }
            }
        }
    }
    getTotalAmount = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetTotalAmountListcallID = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBusinessIncomeAmountListAPI
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }

    handleSubmitSmallBusinessForm = async (data: any) => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };
        let body:any = {
            "small_business": {
                "business_or_profession": data.business_or_profession,
                "name_of_business": data.name_of_business,
                "business_code": data.business_code,
                "gross_receipt_or_turnover": Number(data.gross_receipt_or_turnover),
                "gross_receipt_through_cheques": Number(data.gross_receipt_through_cheques),
                "gross_receipt_through_cash": Number(data.gross_receipt_through_cash),
                "gross_profit": Number(data.gross_profit),
                "expenditure_related_to_activity": Number(data.expenditure_related_to_activity),
                "net_profit": Number(data.net_profit),
                "financial_particular_attributes": {
                    "cash_balance": Number(data.cash_balance),
                    "inventory_stock": Number(data.inventory_stock),
                    "amounts_payable": Number(data.amounts_payable),
                    "amounts_receivable": Number(data.amounts_receivable)
                }
            }
        }
        if(this.state.smallBId>0)
        {
            body = {
                "business_or_profession": data.business_or_profession,
                "name_of_business": data.name_of_business,
                "business_code": data.business_code,
                "gross_receipt_or_turnover": Number(data.gross_receipt_or_turnover),
                "gross_receipt_through_cheques": Number(data.gross_receipt_through_cheques),
                "gross_receipt_through_cash": Number(data.gross_receipt_through_cash),
                "gross_profit": Number(data.gross_profit),
                "expenditure_related_to_activity": Number(data.expenditure_related_to_activity),
                "net_profit": Number(data.net_profit),
                "financial_particular_attributes": {
                    "id":136,
                    "cash_balance": Number(data.cash_balance),
                    "inventory_stock": Number(data.inventory_stock),
                    "amounts_payable": Number(data.amounts_payable),
                    "amounts_receivable": Number(data.amounts_receivable)
                }             
               
            }
        }
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.SmallBusinessID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SmallBusinessesEndPoint + (this.state.smallBId > 0 ? `/${this.state.smallBId}` : '')
        );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
              `${this.state.smallBId > 0 ? "PATCH": "POST"}`
            
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    apiCallFunctions = async (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.BusinessCodeID) {
            console.log("BusinessCodeID", responseJson);

            this.setState({
                businessCodeList: responseJson.business_codes
            })
        } else if (apiRequestCallId === this.SmallBusinessID) {
            console.log("SmallBusinessID", responseJson);
            this.setState({
                alertMessageShow: true,
                alertMessage: "Saved Succesfully",
                alertType: "success"
            },()=>{
            this.props.changeTab("Business Income")

            })
        }
    }
    handleopenfuturesOrIntraday = () => {
        this.setState({ futureIntraday: !this.state.futureIntraday })

    }

    handleOpenPartnershipFirmCategory = () => {
        this.setState({ partnershipFirmCat: !this.state.partnershipFirmCat })
    }

    handleSendDataForPartnershipFrim = (type: string) => {
        this.setState({ type: type, partnershipFirmForm: !this.state.partnershipFirmForm, partnershipFirmCat: false })
    }

    handleSendData = (type: string) => {
        this.setState({ type: type, futuresAndOptions: !this.state.futuresAndOptions, futureIntraday: false }, 
            ()=>{this.getTotalAmount()})
    }
    handleopenregularIncome = () => {
        this.setState((prev) => ({
            ...prev,
            regularBusinessIncome: !prev.regularBusinessIncome
        }))
    }
    handleValidateNatureOfBusiness = (newErrors: any) => {

        if (this.state.smallBusinessFields.business_or_profession === "" || this.state.smallBusinessFields.business_or_profession === null) {
            newErrors.business_or_profession = 'Required!';
        }
        if (this.state.smallBusinessFields.name_of_business === "" || this.state.smallBusinessFields.name_of_business === null) {
            newErrors.name_of_business = 'Required!';
        }
        if (this.state.smallBusinessFields.business_code === "" || this.state.smallBusinessFields.business_code === null) {
            newErrors.business_code = 'Required!';
        }
    }
    handleValidateOtherInc = (newErrors: any) => {
        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.gross_receipt_through_cheques) && this.state.smallBusinessFields.gross_receipt_through_cheques !== "" && this.state.smallBusinessFields.gross_receipt_through_cheques !== null) {
            newErrors.gross_receipt_through_cheques = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.gross_receipt_through_cash) && this.state.smallBusinessFields.gross_receipt_through_cash !== "" && this.state.smallBusinessFields.gross_receipt_through_cash !== null) {
            newErrors.gross_receipt_through_cash = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.gross_profit) && this.state.smallBusinessFields.gross_profit !== "" && this.state.smallBusinessFields.gross_profit !== null) {
            newErrors.gross_profit = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.expenditure_related_to_activity) && this.state.smallBusinessFields.expenditure_related_to_activity !== "" && this.state.smallBusinessFields.expenditure_related_to_activity !== null) {
            newErrors.expenditure_related_to_activity = 'Please Enter a valid Number';
        }
        // this.handleValidateNatureOfBusiness(newErrors)
    }
    handleValidatefinancialParticularsError = (newErrors: any) => {
        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.cash_balance) && this.state.smallBusinessFields.cash_balance !== "" && this.state.smallBusinessFields.cash_balance !== null) {
            newErrors.cash_balance = 'Please Enter a valid Number';
        }

        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.inventory_stock) && this.state.smallBusinessFields.inventory_stock !== "" && this.state.smallBusinessFields.inventory_stock !== null) {
            newErrors.inventory_stock = 'Please Enter a valid Number';
        }

        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.amounts_payable) && this.state.smallBusinessFields.amounts_payable !== "" && this.state.smallBusinessFields.amounts_payable !== null) {
            newErrors.amounts_payable = 'Please Enter a valid Number';
        }

        if (!/^\d+(\.\d+)?$/.test(this.state.smallBusinessFields.amounts_receivable) && this.state.smallBusinessFields.amounts_receivable !== "" && this.state.smallBusinessFields.amounts_receivable !== null) {
            newErrors.amounts_receivable = 'Please Enter a valid Number';
        }
    }
    handleSetActiveBusinessForm = (value: any) => {
        let newErrors: any = {}
        let newErrorfinancialParticulars: any = {}
        if (this.state.activeSmallBusinessForm === 1) {
            this.handleValidateOtherInc(newErrors)
            if (Object.keys(newErrors).length === 0) {
                this.setState({
                    activeSmallBusinessForm: value,
                    activeSmallBusinessFormError: {},
                    smallBusinessFields: {
                        ...this.state.smallBusinessFields,
                    }
                })
            } else {
                this.setState({
                    activeSmallBusinessFormError: newErrors
                })
            }
        } else {
            this.handleValidatefinancialParticularsError(newErrors)
            this.isFormFilled(newErrors, this.state.smallBusinessFields)
            console.log("newErrorsnewErrorsnewErrors", newErrors);

            if (Object.keys(newErrors).length === 0) {
                this.setState({
                    financialParticularsError: {},
                    alertMessageShow: true,
                    alertMessage: "Saved Succesfully",
                    alertType: "success"
                })
                this.handleSubmitSmallBusinessForm(this.state.smallBusinessFields)

            } else if (newErrors.error === false) {
                this.setState({
                    alertMessageShow: true,
                    alertMessage: "Atleast one field is required",
                    alertType: "error"
                })
            } else {
                this.setState({
                    financialParticularsError: newErrors
                })
            }
        }
    }
    handleNavigateToBackPage = () => {
        if (this.state.activeSmallBusinessForm === 2) {
            this.setState({
                
                activeSmallBusinessForm: 1,
                financialParticularsError: {}
            })
        } else {
            this.props.changeTab("Business Income")
        }
    }
    handleCalculateGrossReceiptsAndTurnover = (gross_receipt_through_cheques: any, gross_receipt_through_cash: any) => {
        this.setState({
            smallBusinessFields: {
                ...this.state.smallBusinessFields,
                "gross_receipt_or_turnover": gross_receipt_through_cheques + gross_receipt_through_cash
            }
        })
    }
    hamdleValidateFormValueOnChange = (name: any, value: any) => {

        if (this.state.activeSmallBusinessForm === 1) {
            if (!/^\d+(\.\d+)?$/.test(value) && value !== "") {
                this.setState(prevState => ({
                    activeSmallBusinessFormError: {
                        ...prevState.activeSmallBusinessFormError,
                        [name]: "Please Enter a valid Number"
                    }
                }))
            } else {
                this.setState(prevState => ({
                    activeSmallBusinessFormError: {
                        ...prevState.activeSmallBusinessFormError,
                        [name]: null
                    }
                }))
            }
        } else {
            if (!/^\d+(\.\d+)?$/.test(value) && value !== "") {
                this.setState(prevState => ({
                    financialParticularsError: {
                        ...prevState.financialParticularsError,
                        [name]: "Please Enter a valid Number"
                    }
                }))
            } else {
                this.setState(prevState => ({
                    financialParticularsError: {
                        ...prevState.financialParticularsError,
                        [name]: null
                    }
                }))
            }
        }
    }
    handleSetSmallBusinessValue = (e: any) => {
        const { name, value } = e.target
        const {
            gross_receipt_through_cheques,
            gross_receipt_through_cash,
            gross_profit,
            expenditure_related_to_activity,
        } = this.state.smallBusinessFields
        const isDecimal = (val: string) => /^(\d+(\.\d*)?|\.\d+)?$/.test(val);
        const decimalFields = [
            "gross_receipt_through_cheques",
            "gross_receipt_through_cash",
            "gross_profit",
            "expenditure_related_to_activity",
            "cash_balance",
            "inventory_stock",
            "amounts_payable",
            "amounts_receivable"
        ];
        if (decimalFields.includes(name) && !isDecimal(value)) {
            return; 
        }
        this.setState((prevState) => ({
            smallBusinessFields: {
                ...prevState.smallBusinessFields,
                [name]: value
            }
        }))
        this.hamdleValidateFormValueOnChange(name, value);

        if (name === "gross_receipt_through_cash") {
            this.setState((prevState) => ({
                smallBusinessFields: {
                    ...prevState.smallBusinessFields,
                    "gross_receipt_or_turnover": Number(gross_receipt_through_cheques) + Number(value)
                }
            }))
        } else if (name === "gross_receipt_through_cheques") {
            this.setState((prevState) => ({
                smallBusinessFields: {
                    ...prevState.smallBusinessFields,
                    "gross_receipt_or_turnover": Number(gross_receipt_through_cash) + Number(value)
                }
            }))
        } else if (name === "gross_profit") {
            this.setState((prevState) => ({
                smallBusinessFields: {
                    ...prevState.smallBusinessFields,
                    "net_profit": Number(value) - Number(expenditure_related_to_activity)
                }
            }))
        } else if (name === "expenditure_related_to_activity") {
            this.setState((prevState) => ({
                smallBusinessFields: {
                    ...prevState.smallBusinessFields,
                    "net_profit": Number(gross_profit) - Number(value)
                }
            }))
        }
    }
    handleSetBusinessProfession = (e: any, value: any) => {
        console.log("valuevaluevaluevalue", value);

        this.setState((prevState) => ({
            smallBusinessFields: {
                ...prevState.smallBusinessFields,
                "business_or_profession": value
            }
        }))
    }
    handleSetBusinessCode = (e: any, value: any) => {

        this.setState((prevState) => ({
            smallBusinessFields: {
                ...prevState.smallBusinessFields,
                "business_code": value
            }
        }))
    }
    handleSetBusinessName = (e: any) => {
        const { value } = e.target;
        
        // Remove any special characters from the input value using regex
        const cleanedValue = value.replace(/[^a-zA-Z0-9 ]/g, '');
        if (value.trim() === "" && value !== "") {
            return;
        }
    
        // Update the state with the cleaned value
        this.setState((prevState) => ({
            smallBusinessFields: {
                ...prevState.smallBusinessFields,
                name_of_business: cleanedValue
            }
        }));
    }    

    getBusinessCodeList = async () => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.BusinessCodeID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BusinessCodeEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    handleTostClose = () => {
        this.setState({
        toast: {
            open: false,
            message: "error.message",
            type: "error",
        },
        });
    }

    receiveAmountResponse = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const { regular_business_income, income_from_partnership_firms_or_playing_of_truks,
                small_business, futures_option_and_intraday, professional_income,income_from_business } = responseJson.data.attributes
            this.setState({
                regular_business: regular_business_income,
                income_from_partnership_firms_or_playing_of_truks: income_from_partnership_firms_or_playing_of_truks,
                small_business: small_business,
                income_from_futures_or_intraday: futures_option_and_intraday,
                professional_or_business_income: professional_income,
                business_income:income_from_business
            })
        }
    }
    handleCloseMessageAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ alertMessageShow: false })
    };
    getSmallBusiness = async () => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };
      
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSmallBusinessApiID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSmallBApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    getUserDetails = async () => {

        const header = {
          "Content-Type": configJSON.dashboarContentType,
          "token": await getStorageData(configJSON.authToken)
    
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.userDetailId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.panCardEndpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }
    // Customizable Area End
}
