import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Hidden,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ForgetPasswordController, {
  Props
} from "./ForgetPasswordController.web";
// Customizable Area Start
import OTPInput from "../../../components/src/OTPInput.web";
import Toster from "../../../components/src/Toster";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area end

export default class ForgetPassword extends ForgetPasswordController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  otpField = () => {
    return (
      <>
        <Typography variant="body2" align="left" style={style.error}>
          {this.state.otpError}
        </Typography>
        <OTPInput
          otp={`${this.state.otp}`}
          handleOtpChange={this.handleOtpChange}
          separator=""
          blocks={6}
        />
      </>
    );
  };

  communInputField = (
    name: string,
    _label: string,
    placeholder: string,
    type: string,
    handleShowHidePassword?: any,
  ) => {

    let checkType = () => {
      if(name === "password"){
        return this.state.showPassword ? "text" : "password"
      }else if(name === "confirmPassword"){
        return this.state.showConfirmPassword ? "text" : "password"
      }
      return type;
    }

    let showEye = name === 'password' ? this.state.showPassword : this.state.showConfirmPassword
    let stateName = name === 'password' ? 'showPassword' : 'showConfirmPassword'

    return (
      <Box
        component="div"
        style={
          name !== "email"
            ? { ...style.fieldContainer }
            : { ...style.fieldContainerEmail }
        }
      >
        <TextField
          data-test-id={`${name}-id`}
          name={name}
          className="input-field"
          placeholder={placeholder}
          fullWidth
          value={this.state.formData[name]}
          margin="normal"
          type={checkType()}
          variant="outlined"
          inputProps={{
            style: {
              height: "0.5rem",
              color: "#fff"
            }
          }}
          InputProps={{
            style: {
              border: "1px solid white",
              borderRadius: "5px",
              color: "#fff",
            },
            endAdornment:
              name !== "email" ? (
                <InputAdornment position="end">
                  <IconButton
                    data-test-id='btnTogglePassword'
                    aria-label="toggle password visibility"
                    onClick={() => handleShowHidePassword(stateName)}
                  >
                    {showEye ? <Visibility style={{color: "white"}} /> : <VisibilityOff style={{color: "white"}} />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          onChange={(e: { target: { name: string; value: string } }) =>
            this.handleChange(e)
          }
        />
        <Typography variant="body2" component="div" style={style.error}>
          {this.state.errors[name]}
        </Typography>
      </Box>
    );
  };

  EmailBody = () => {
    return (
      <Box
        component="div"
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{width: "500px", padding: "10px", margin: "20px 10px 10px 10px" }}
      >
        <DialogTitle id="alert-dialog-title" style={{ ...style.titleE }}>
          <CloseIcon
              data-test-id="closeDialog"
              className="closeBtn"
              onClick={this.handleCloseCrossIcon}
            />
          Enter Your Email address
        </DialogTitle>
        <DialogContent className="content-email">
          {this.communInputField("email", "Email", "Enter Email", "text")}
          <DialogActions className="otpAction">
            <Button
              data-test-id="submit-otp-id"
              style={{ ...style.submitBtn }}
              variant="contained"
              onClick={this.sendOtp}
            >
              Send OTP
            </Button>
          </DialogActions>
        </DialogContent>
      </Box>
    );
  };

  defaultBody = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title" className="default-title">
          <CloseIcon
            data-test-id="closeDialogDefaultBody"
            className="closeBtn"
            onClick={this.handleCloseCrossIcon}
          />
          Set Your New Password.
        </DialogTitle>
        <DialogContent className="default-content">
          {!this.state.isVerified ? (
            <>
              <Typography
                variant="subtitle1"
                align="center"
                style={style.subtitle1}
              >
                Verification code was sent to you on {this.state.formData.email}{" "}
              </Typography>
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ margin: "18px 0" }}
              >
                {this.otpField()}
              </Box>
              <Box component="div" display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "85%", margin: "7px auto 12px auto" }}>
                <Typography align="right" variant="body2" className="timer">
                  {this.state.second > 0 && `Resend OTP after: 00:${this.state.second.toString().padStart(2, '0')}`}
                </Typography>
                <Typography align="right" variant="button" className="resendBtn" onClick={this.sendOtp}>{this.state.second == 0 && "Resend code"}</Typography>
              </Box>
              <DialogActions className="pass-action">
                <Button
                  data-test-id="verify-id"
                  onClick={this.handleVerify}
                  style={{ ...style.submitBtn }}
                  variant="contained"
                >
                  Verify
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                align="center"
                style={style.subtitle1}
              >
                Enter new password{" "}
              </Typography>
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                {this.communInputField(
                  "password",
                  "Password",
                  "Your Password",
                  "password",
                  this.handleShowHidePassword,
                )}
                {this.communInputField(
                  "confirmPassword",
                  "Confirm Password",
                  "Confirm Password",
                  "password",
                  this.handleShowHidePassword,
                )}
              </Box>
              <DialogActions className="pass-action">
                <Button
                  data-test-id="submit-id"
                  onClick={this.handleSubmit}
                  style={{ ...style.submitBtn }}
                  variant="contained"
                  disabled={!this.state.formData.password || !this.state.formData.confirmPassword}
                >
                  Set new password
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            elevation: 0,
            style: {
              backgroundColor: "#1b222e",
              border: "1px solid #fff",
              borderRadius: "10px"
            }
          }}
          disableScrollLock={true}
        >
          <Wrapper component="div" sx={{ padding: "20px" }}>
            {this.state.isOTPsend ? this.defaultBody() : this.EmailBody()}
          </Wrapper>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const Wrapper = styled(Box)({
  "& .closeBtn": {
    cursor: "pointer",
    top: 25,
    position: "absolute" as const,
    color: "#337152",
    right: 25,
  },
  "& .otpAction": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px 0"
  },
  "& .default-title": {
    color: "#fff",
    textAlign: "center"
  },
  "& .default-content": {
    width: "500px",
    padding: "0px 50px 30px 50px"
  },
  "& .pass-action": {
    justifyContent: "center",
    flexDirection: "column"
  },
  "& .input-field": {
    boxSizing: "border-box",
    margin: 0,
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
            borderWidth: "0.3px",
        },
    },
  },
  "& .content-email": {
    width: "100%",
    padding: 0
  },
  "& .resendBtn": {
    color: "#7292cd", 
    textDecoration: "underLine", 
    cursor: "pointer",
    textTransform: "none",
  },
  "& .timer": { color: "#fff" },
});

const style = {
  fullWidth: {
    width: "100%",
    height: "100%"
  },
  background: {
    width: "100%",
    height: "100%",
    background: "#8baef2"
  },
  submitBtn: {
    margin: "10px 0",
    backgroundColor: "#2b664b",
    width: "85%",
    marginBottom: "5px",
    color: "#fff"
  },
  titleE: {
    color: "#fff",
    margin: "10px 20px"
  },
  title: {
    color: "#fff"
  },
  subtitle1: { color: "#fff", marginBottom: "1.1rem" },
  fieldContainer: { width: "100%" },
  fieldContainerEmail: { width: "90%", margin: "auto" },
  error: { margin: "5px 0 10px 0px", color: "red" }
};
// Customizable Area End
